import React from 'react';

import './video.scss';
import videoEN from '../videos/Enspryng_Patient_Injection_Training_Mat_EN.mp4';
import videoENCC from 'file-loader!../videos/Enspryng_Patient_Injection_Training_Mat_EN.vtt';
import videoFR from '../videos/Enspryng_Patient_Injection_Training_Mat_FR.mp4';
import videoFRCC from 'file-loader!../videos/Enspryng_Patient_Injection_Training_Mat_FR.vtt';

import { TranslatedBlock } from './language';

const Video = () => {
  return (
    <div className="video-container">
      <div className="video-wrapper">
        <TranslatedBlock language="english">
          <video controls src={videoEN} style={{ width: '100%' }}>
            <track label="English" kind="captions" srcLang="en" src={videoENCC} />
          </video>
        </TranslatedBlock>
        <TranslatedBlock language="french">
          <video controls src={videoFR} style={{ width: '100%' }}>
            <track label="Français" kind="captions" srcLang="fr" src={videoFRCC} />
          </video>
        </TranslatedBlock>
      </div>
    </div>
  );
};

export default Video;
