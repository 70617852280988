import React, { Component } from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import Video from 'src/components/video';
import Annotation from 'src/components/annotation';
import { TranslatableText } from 'src/components/language';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('patient');
  }

  render() {
    return (
      <Layout
        title={{
          english: 'ENSPRYNG Administration Video',
          french: 'Vidéo d’administration de ENSPRYNG',
        }}
        showTab={true}
        className="patient"
      >
        <h2>
          <TranslatableText
            dictionary={{
              english: 'ENSPRYNG Administration Video',
              french: 'Vidéo d’administration de ENSPRYNG',
            }}
          />
        </h2>

        <Video />
        <Annotation />
      </Layout>
    );
  }
}

export default PatientPage;
